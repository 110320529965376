
import Vue from 'vue';

export default Vue.extend({
  name: 'SearchInput',

  props: {
    autoFocus: {
      type: Boolean,
      default: false,
    },

    syncFromQuery: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      searchValue: (this.syncFromQuery ? this.$route.query.s || '' : '') as string,
      isFirstEdit: true,
    };
  },

  computed: {
    icon (): any {
      return this.searchValue ? {
        name: 'close',
        alt: 'Очистить поле',
        value: 'clear',
      } : {
        name: 'search',
        alt: 'иконка поиска',
        value: 'search',
      };
    },
    iconClass (): Record<string, boolean> {
      return {
        'search-input-wrapp__icon-clear': this.icon.value === 'clear',
      };
    },
  },

  watch: {
    '$route.query.s' (query: string) {
      if (this.syncFromQuery) {
        this.searchValue = query;
        this.$emit('search', query);
      }
    },
  },

  mounted () {
    if (this.autoFocus) {
      this.inputFocus();
    }
  },

  methods: {
    clickIcon () {
      if (!this.searchValue) { return; }

      this.searchValue = '';
      this.inputFocus();

      this.$sendYandexMetrika({
        level1: 'Событие_Поиск',
        level6: 'Очистка запроса',
      });
    },

    inputFocus (): void {
      const inputSearch = this.$refs.inputSearch as HTMLElement;
      inputSearch.focus();
    },

    setValue (value: string) {
      this.searchValue = value.trim();

      if (this.isFirstEdit) {
        this.$sendYandexMetrika({
          level1: 'Событие_Поиск',
          level6: 'Ввод запроса',
        });
      }

      this.isFirstEdit = false;
    },

    search () {
      if (!this.searchValue) { return; }

      this.$sendYandexMetrika({
        level1: 'Событие_Поиск',
        level6: 'Отправка запроса',
      });

      let { href } = this.$router.resolve({
        name: 'search',
        query: { s: this.searchValue },
      });

      // Нужно для консистентности, чтобы сохранить / в url
      if (href[href.indexOf('?') - 1] !== '/') {
        href = href.replace('?', '/?');
      }

      if (this.$route.name === 'search') {
        this.$router.push(href);
        this.$emit('search', this.searchValue);
      } else {
        location.href = href;
      }
    },
  },
});
