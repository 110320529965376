
import Vue, { PropType } from 'vue';
import { Img } from '@devhacker/shared/utils/image/types';
import { getFullUrl, isMobile, isTablet, trailingSlash } from '~/utils';
import TileContainer from '@/containers/TilesContainer/TileContainer.vue';
import { TTile } from '@/containers/TilesContainer/types';

type catsItem = {
  author: undefined | string
  icon: string
  image: Array<Img>
  name: string
  profile: undefined | string
  slug: string
  url: undefined | string
}

export default Vue.extend({
  name: 'TilesRubrics',

  components: {
    TileContainer,
  },

  props: {
    items: {
      type: Array as PropType<Array<catsItem>>,
      required: true,
    },
  },

  data (): { tiles: Array<TTile> | []; enableSwiper: boolean, showArrows: boolean } {
    return {
      tiles: [],
      enableSwiper: false,
      showArrows: false,
    };
  },

  fetch () {
    this.tiles = this.items.map((item) => {
      return {
        title: item.name,
        image: item.icon,
        link: trailingSlash(item.slug),
        text: '',
        isUploadImage: true,
      };
    });
  },

  computed: {
    computedStyles (): string {
      return `
      --tile-width-mobile: ${this.enableSwiper ? '40%' : '0'};
      --tile-width-tablet: ${this.enableSwiper ? '147px' : '0'};
      --tile-flex: ${this.enableSwiper ? '1 1 auto' : '1 1 0'};
      `;
    },
    computedClass (): Record<string, boolean> {
      return {
        'tiles-rubrics-disabled-swaper': !this.enableSwiper,
      };
    },
  },

  mounted () {
    if (isMobile()) {
      this.enableSwiper = true;
    } else if (isTablet() && this.tiles.length > 5) {
      this.enableSwiper = true;
    } else {
      this.showArrows = this.tiles.length >= 6;
      this.enableSwiper = this.tiles.length >= 6;
    }
  },

  methods: {
    sendAnalytics (tile: TTile) {
      this.$sendYandexMetrika({
        level1: 'Клик_Плитка рубрик',
        level4: getFullUrl(`/health/${tile.link}/`),
        level6: tile.title,
      });
    },

    sendScrollAnalytics () {
      this.$sendYandexMetrika({
        level1: 'Скролл_Плитка рубрик',
        level6: '1',
      });
    },
  },
});
