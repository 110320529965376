
import Vue, { PropType } from 'vue';

import MascotFooter from '../Mascot/MascotFooter.vue';
import TheFooterMenu from '~/components/TheFooterMenu.amp/TheFooterMenu.amp.vue';
import PushControlButton from '~/components/PushControlButton.vue';
import { isHealthPath } from '~/router';

export default Vue.extend({
  name: 'TheFooter',

  components: {
    TheFooterMenu,
    PushControlButton,
    MascotFooter,
  },

  props: {
    menu: {
      type: Array as PropType<Array<FooterMenu>>,
      required: true,
    },
  },

  computed: {
    isHealth (): boolean {
      return isHealthPath(this.$route.path);
    },
  },
});
