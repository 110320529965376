// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./images/app-store.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./images/google-play.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".footer-menu{display:flex;flex-direction:column;margin-bottom:16px}@media(min-width:768px){.footer-menu{flex-direction:row}.footer-menu,.footer-menu:not(:last-child){margin-bottom:0}}@media(min-width:1280px){.footer-menu:last-child .footer-menu__item{margin-bottom:0}}.footer-menu__title{color:#000;color:#a0a2a7;font-family:\"PT Root\",\"PT Root Fallback\",Arial,sans-serif;font-size:16px;font-weight:700;line-height:24px;margin-bottom:16px}@media(min-width:768px){.footer-menu__title{margin-right:24px}}@media(min-width:1280px){.footer-menu__title{margin-bottom:0}}.footer-menu__item{color:#000;color:#fffc;font-family:\"PT Root\",\"PT Root Fallback\",Arial,sans-serif;font-size:16px;font-weight:700;line-height:24px;margin-right:24px;text-decoration:none;white-space:normal}@media(min-width:768px){.footer-menu__item{white-space:nowrap}}@media(hover:hover){.footer-menu__item:hover{color:#fff}}.footer-menu__item--title{color:#000;color:#a0a2a7;font-family:\"PT Root\",\"PT Root Fallback\",Arial,sans-serif;font-size:16px;font-weight:700;line-height:24px;width:100%}@media(min-width:768px){.footer-menu__item--title{margin-right:24px;width:auto}}@media(min-width:1280px){.footer-menu__item--title{margin-bottom:0}}@media(hover:hover){.footer-menu__item--title:hover{color:#a0a2a7}}.footer-menu__item--android,.footer-menu__item--ios{margin-left:23px;position:relative}.footer-menu__item--android:before,.footer-menu__item--ios:before{content:\"\";height:18px;left:-23px;position:absolute;top:3px;width:18px}.footer-menu__item--ios:before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.footer-menu__item--android:before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.footer-menu__list{display:flex;flex-wrap:wrap;margin-bottom:0;row-gap:16px}@media(min-width:768px){.footer-menu__list{row-gap:8px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
