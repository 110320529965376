
import Vue from 'vue';
import { isHealthPath } from '~/router';
import { getFullUrl } from '~/utils';
import { IS_NEW_YEAR_LOGO } from '~/constants';
export default Vue.extend({
  name: 'TheHeaderAmp',

  computed: {
    healthLogoName (): string {
      return IS_NEW_YEAR_LOGO ? 'new-year-logo-white' : 'health-logo-white';
    },

    logoName (): string {
      return IS_NEW_YEAR_LOGO ? 'new-year-logo-white' : 'lh-logo-white';
    },

    isHealthPath (): boolean {
      return isHealthPath(this.$route.path);
    },
  },

  methods: {
    getAnalyticsLink (link: string): string {
      return getFullUrl(link);
    },
  },
});
